import React, { Component, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Link, injectIntl } from "gatsby-plugin-intl";
import { useSpring, animated } from "react-spring";
import SVG from "react-inlinesvg";
// import Grid from "styled-components-grid";
// import Flickity from "react-flickity-component";

import {
  // mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from '../components/block-text'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import Card from "../components/card";
import SquareCard from "../components/square-card";
import SideBarLeft from "../components/sidebar-left";
import SideBarRight from "../components/sidebar-right";
import Content, { ContentContainer, ContentDivider, ContentImage, ContentImageContainer } from "../components/content";
// import GridLines from "../components/gridlines";
// import { Heading2, Heading3 } from "../components/heading";
import Mono, { MonoHeading2 } from "../components/mono";
import RatioBox from "../components/ratio-box";
import { Heading2, Heading3 } from "../components/heading";
import SizeAware from "../components/size-aware";
import LetterSvg from "../images/huiput-2024.svg";
import CommunitySvg from "../images/community.svg";
import CommunityHorizontalSvg from "../images/community-horizontal.svg";
import Logo from "../images/huiput-horizontal.svg";
import SloganFi0 from "../images/slogan/HUIPUT_slogan_FI-00.svg";
import SloganFi1 from "../images/slogan/HUIPUT_slogan_FI-01.svg";
import SloganFi2 from "../images/slogan/HUIPUT_slogan_FI-02.svg";
import SloganFi3 from "../images/slogan/HUIPUT_slogan_FI-03.svg";
import SloganFi4 from "../images/slogan/HUIPUT_slogan_FI-04.svg";
import SloganFi5 from "../images/slogan/HUIPUT_slogan_FI-05.svg";

import SloganEn0 from "../images/slogan/HUIPUT_slogan_EN-00.svg";
import SloganEn1 from "../images/slogan/HUIPUT_slogan_EN-01.svg";
import SloganEn2 from "../images/slogan/HUIPUT_slogan_EN-02.svg";
import SloganEn3 from "../images/slogan/HUIPUT_slogan_EN-03.svg";
import SloganEn4 from "../images/slogan/HUIPUT_slogan_EN-04.svg";
import SloganEn5 from "../images/slogan/HUIPUT_slogan_EN-05.svg";
import SloganEn6 from "../images/slogan/HUIPUT_slogan_EN-06.svg";

export const query = graphql`
  fragment HomeSpeakers on SanitySpeaker {
    id
    slug {
      current
    }
    title {
      fi
      en
    }
    author
    color1
    color2
    country
    publishedAt
    listingImage {
      _key
      _type
      caption
      alt
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
        id
        url
        fixed {
          aspectRatio
          width
          height
          src
          srcSet
          srcWebp
          srcSetWebp
        }
        fluid {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    _rawBody
    website
    _rawEmbedCode
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      _rawDescription
      keywords
    }
    homepage: sanityHomepage {
      id
      _rawHomepageTitle
      _rawHomepageBody
      _rawHomepageHero
      _rawProgrammeTitle
      homepageImage {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      heroImage1 {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      heroImage2 {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      heroImage3 {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      heroImage4 {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      heroImage5 {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      heroImage6 {
        _key
        _type
        caption
        alt
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
    keynoteSpeakers: allSanitySpeakerListing {
      edges {
        node {
          id
          keynoteSpeakers {
            ...HomeSpeakers
          }

        }
      }
    }
    speakers: allSanitySpeakerListing {
      edges {
        node {
          id
          speakers {
            ...HomeSpeakers
          }
        }
      }
    }
  }
`;

const StyledOverflow = styled.div`
  overflow: hidden;
  margin-bottom: 2px;
  padding-right: 1px;
  ${breakpoint("lg")`
    height: 100%;
  `}
`;

const IndexPage = ({ intl, ...props }) => {
  const { data, errors } = props;
  const locale = intl.locale || "en";

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
/*
  if (locale == 'fi') {
    var sloganfile = 'SloganFi';
    var slogancount = 5;
  } else {
    var sloganfile = 'SloganEn';
    var slogancount = 7;
  }

  var slogans = [];

  for (let i = 1; i <= slogancount; i++) {
    const filename = sloganfile + i;
    slogans.push(filename);
  } 
              <React.Fragment>
              {slogans.map((file, index) => {
                var order = index+1;
                console.log(order);
                return <SloganAnimation order={order} lang={locale}></SloganAnimation>
              })}
              </React.Fragment>

  */

  const keynoteSpeakerNodes = (data || {}).keynoteSpeakers
    ? data.keynoteSpeakers.edges[0].node.keynoteSpeakers
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const speakerNodes = (data || {}).speakers
    ? data.speakers.edges[0].node.speakers
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <React.Fragment>
      <SEO
        title={site.title}
        description={site._rawDescription[locale]}
        keywords={site.keywords}
        lang={locale}
      />
      <StyledBorderGridWrapper>
        <BorderGrid>
          <BorderGridCol size={{ xs: 1 / 1}} className="letteranimation">
            <StyledOverflow>
              <RatioBox ratio="2/3" ratio_md="1/1">
                <LetterAnimations headerHeight={props.headerHeight} data={data.homepage} />
              </RatioBox>
            </StyledOverflow>
          </BorderGridCol>
        </BorderGrid>
      </StyledBorderGridWrapper>
      {/* <ContentDivider /> */}
      {/* <ContentDivider /> */}
      <BorderGrid>
      <BorderGridCol size={{ xs: 1 / 1 }}>
        <StyledHomepageSloganContainer>
          <Mobile>
            <StyledHeading2>{intl.messages.title}</StyledHeading2>
          </Mobile>
          <RatioBox ratio="2/1" bgColor="orange">
            <SloganAnimationContainer>
              {(locale == "en") && (
                <React.Fragment>
                  <StyledSloganSVG src={SloganEn0} type="image/svg+xml" />
                  <SloganAnimation order={3} file={SloganEn1}></SloganAnimation>
                  <SloganAnimation order={4} file={SloganEn2}></SloganAnimation>
                  <SloganAnimation order={5} file={SloganEn3}></SloganAnimation>
                  <SloganAnimation order={6} file={SloganEn4}></SloganAnimation>
                  <SloganAnimation order={7} file={SloganEn5}></SloganAnimation>
                  <SloganAnimation order={8} file={SloganEn6}></SloganAnimation>
                  <SloganAnimation order={9} file={SloganEn0}></SloganAnimation>
                </React.Fragment>
              )}
              {(locale == "fi") && (
                <React.Fragment>
                  <StyledSloganSVG src={SloganFi0} type="image/svg+xml" />
                  <SloganAnimation order={3} file={SloganFi1}></SloganAnimation>
                  <SloganAnimation order={4} file={SloganFi2}></SloganAnimation>
                  <SloganAnimation order={5} file={SloganFi3}></SloganAnimation>
                  <SloganAnimation order={6} file={SloganFi4}></SloganAnimation>
                  <SloganAnimation order={7} file={SloganFi5}></SloganAnimation>
                  <SloganAnimation order={8} file={SloganFi0}></SloganAnimation>
                </React.Fragment>
              )}
              
            </SloganAnimationContainer>
          </RatioBox>
          <Mobile>
            <PlaceContainer>
              <StyledHeading2>{intl.messages.date} {intl.messages.location_1} {intl.messages.location_2}</StyledHeading2>
            </PlaceContainer>
          </Mobile>
        </StyledHomepageSloganContainer>
      </BorderGridCol>
      <BorderGrid>
      <StyledFlexContent>
        <SideBarLeft word={intl.messages.menu_programme} fill="var(--color-orange)"></SideBarLeft>
          <StyledPanelContentCenter id="center-scroller">
          <StyledCenteredContent>
            <StyledHeading2>{data.homepage._rawProgrammeTitle[intl.locale]}</StyledHeading2>
          </StyledCenteredContent>
          {(speakerNodes && speakerNodes.length) && (
            <React.Fragment>
              {speakerNodes.slice(0, 4).map((node, index) => {
                return (
                  <BorderGridCol key={`node-2-${node.id}`} size={{ xs: 1 / 1 }}>
                    <CardComponent node={node} locale={locale} />
                  </BorderGridCol>
                )
              })}
            </React.Fragment>
          )}
          <BorderGridCol size={{ xs: 1 / 1 }}>
            <StyledCenteredContent>
              <StyledProgramme to={`/programme/`}>
                  <StyledHeading2>{intl.messages.programme} <strong>↗</strong></StyledHeading2>
              </StyledProgramme>
            </StyledCenteredContent>
          </BorderGridCol>
          </StyledPanelContentCenter>
          <SideBarRight word={intl.messages.menu_programme} fill="var(--color-orange)"></SideBarRight>
        </StyledFlexContent>
      </BorderGrid>
      <ContentDivider />
      <Mobile>
        <StyledCommunityContent>
          <StyledCommunityHeader>
            <img src={CommunityHorizontalSvg} alt="Community" />
          </StyledCommunityHeader>
          <StyledHuiputHeader>
            <img src={Logo} alt="Huiput" />
          </StyledHuiputHeader>
          <StyledCommunityHeader>
            <img src={CommunityHorizontalSvg} alt="Community" />
          </StyledCommunityHeader>
          <StyledHuiputHeader>
            <img src={Logo} alt="Huiput" />
          </StyledHuiputHeader>
        </StyledCommunityContent>
      </Mobile>
        <BorderGridCol size={{ xs: 1 / 1, md: 1 / 2 }}>
          <StyledHomepageContentContainer>
            <StyledHomepageContent>
              <ContentContainer>
                <BlockText blocks={data.homepage._rawHomepageBody[intl.locale]} />
              </ContentContainer>
            </StyledHomepageContent>
          </StyledHomepageContentContainer>
        </BorderGridCol>
        <BorderGridCol size={{ xs: 1 / 1, md: 1 / 2 }}>
          <Desktop>
            <StyledHuiputContent className="first-huiput">
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
            </StyledHuiputContent>
            <StyledCommunityContent>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
            </StyledCommunityContent>
            <StyledHuiputContent>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
            </StyledHuiputContent>
            <StyledCommunityContent>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
            </StyledCommunityContent>
            <StyledHuiputContent>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
            </StyledHuiputContent>
            <StyledCommunityContent>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
            </StyledCommunityContent>
            <StyledHuiputContent>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
            </StyledHuiputContent>
            <StyledCommunityContent>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
            </StyledCommunityContent>
          </Desktop>
          <Mobile>
            <StyledHuiputContent>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
              <StyledHuiputHeader>
                <img src={Logo} alt="Huiput" />
              </StyledHuiputHeader>
              <StyledCommunityHeader>
                <img src={CommunityHorizontalSvg} alt="Community" />
              </StyledCommunityHeader>
            </StyledHuiputContent>
          </Mobile>
        </BorderGridCol>
      </BorderGrid>

      {/* <ContentDivider /> */}
      {/* <ContentDivider /> */}
    </React.Fragment >
  );
};

const StyledHeading2 = styled(Heading2)`
    font-family: var(--font-family);
    font-weight: normal;
    text-transform: uppercase;
    ${breakpoint("lg")`
        font-size: 18px;
        line-height: 28px;
    `}
`;

const Desktop = styled.div`
    display: none;
    width: 100%;
    ${breakpoint("md")`
      display: block;
  `}
`;

const Mobile = styled.div`
    ${breakpoint("md")`
      display: none;
  `}
`;

const StyledHomepageContentContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 30px 0;
  p:last-child {
    margin-bottom: 50px;
  }
  p {
    font-size: 18px;
    line-height: 22px;
  }
  ${breakpoint("md")`
    padding: 0;
    p {
      font-size: 25px;
      line-height: 30px;
    }
    p:last-child {
      margin-bottom: 0px;
    }
  `}
`;

const StyledCommunityContent = styled.div`
  display: flex;
  img {
    height: 50px;
  }
  margin-top: 20px;
  ${breakpoint("md")`
  margin-top: 0px;
    img {
      height: auto;
    }
  `}
`;

const StyledHuiputContent = styled.div`
  display: flex;
  img {
    height: 50px;
  }
  ${breakpoint("md")`
    margin-left: 50px;
    img {
      height: auto;
    }
  `}
`;

const StyledCommunityHeader = styled.span`
  margin-right: 20px;
`;

const StyledHuiputHeader = styled(StyledCommunityHeader)`
  font-family: var(--font-family-bold);
`;

const StyledHomepageSloganContainer = styled.div`
  background-color: var(--color-orange);
  color: var(--color-yellowish);
  padding: 40px 20px 90px 20px;
  p {
    padding: 100px 0;
  }
  ${breakpoint("sm")`
    padding: 40px 20px 110px 20px;
  `}
  ${breakpoint("md")`
    padding: 40px 20px;
  `}
  ${breakpoint("xl")`
    p {
      padding: 0;
    }
  `}
`;

const StyledHomepageContent = styled(Content)`
  padding: 40px 8vw;
  ${breakpoint("md")`
    padding: 40px 9vw;
  `}
`;

const StyledCenteredContent = styled(Content)`
  margin: auto;
  ${breakpoint("md")`
    text-align: center;
    margin: 0 auto 60px auto;
  `}
`;

const StyledProgramme = styled(Link)`
  display: block;
  position: relative;
  height: 100%;
  margin: 20px auto 0 auto;
  ${breakpoint("md")`
    margin: 60px auto 0 auto;
`}
`;

const StyledPanelContentCenter = styled.div`
  width: 100%;
  ${breakpoint("lg")`
    flex-basis: 100vw;
    flex-basis: 100vw;
  `}
`;

const StyledFlexContent = styled(Content)`
  padding: 0px 5px;
  overflow: hidden;
  display: flex;
  width: 100%;
  h3 {
    margin-bottom: 0.5em;
  }
  ${breakpoint("lg")`
    padding: 60px 5px;
  `}
`;

const StyledProgrammeContent = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100%);
  background-color: var(--color-orange);
  ${breakpoint("sm")`
    height: calc(100% + 51px);
  `}
  width: 100%;
  justify-content: center;
  text-align: center;
  a {
    border-bottom: none;
  }
`;

const SloganAnimationContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledRatioBoxWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  ${breakpoint("lg")`
    padding-left: 1px;
  `}
`;


class LetterAnimations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratioBoxHeight: 0
    }
  }

  onSize = size => {
    // document.getElementById('programmescroll').style.height = size.height +'px';
  this.setState({
    ratioBoxHeight: size.height
  })
  }
  render() {
    return (
      <SizeAware onSize={this.onSize}>
        <RatioBox ratio="2/3" ratio_md="1/1">
          <BorderGrid>
            <BorderGridCol size={{ xs: 1 / 4, lg: 1 / 6 }}>
              <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} image={this.props.data.heroImage1} fromY={-850} />
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 4, lg: 1 / 6  }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} headerHeight={this.props.headerHeight} image={this.props.data.heroImage2} reverse={true} fromY={200} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 4, lg: 1 / 6  }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} image={this.props.data.heroImage3} fromY={-350} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{ xs: 1 / 4, lg: 1 / 6  }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} image={this.props.data.heroImage4} reverse={true} fromY={400} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{ lg: 1 / 6  }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} image={this.props.data.heroImage5} fromY={-900} />
              </StyledLetterContainer>
            </BorderGridCol>
            <BorderGridCol size={{  lg: 1 / 6  }}>
              <StyledLetterContainer>
                <LetterAnimationComponent ratioBoxHeight={this.state.ratioBoxHeight} image={this.props.data.heroImage6} reverse={true} fromY={600} />
              </StyledLetterContainer>
            </BorderGridCol>
          </BorderGrid>
        </RatioBox>
      </SizeAware>
    )
  }
}

const CardComponent = (props) => {
  const { node, locale } = props;

  return (
    <Link to={`/programme/${node.slug.current}/`} style={{display: 'block'}}>
      <Card href={`/programme/${node.slug.current}/`} image={
        imageUrlFor(buildImageObj(node.listingImage))
          .width(960)
          .height(592)
          .auto('format')
          .quality(80)
          .saturation(-100)
          .url()}
        imageHover={
          imageUrlFor(buildImageObj(node.listingImage))
            .width(960)
            .height(592)
            .auto('format')
            .quality(80)
            .saturation(-100)
            .url()}
        author={node.author}
        color1={node.color1}
        color2={node.color2}
        country={node.country}
        title={node.title ? node.title[locale] : undefined}
      />
    </Link>
  )
}

const CardComponentSquare = (props) => {
  const { node, locale } = props;

  return (
    <Link to={`/programme/${node.slug.current}/`}>
      <SquareCard href={`/programme/${node.slug.current}/`} image={
        imageUrlFor(buildImageObj(node.listingImage))
          .width(960)
          .height(960)
          .auto('format')
          .quality(80)
          // .saturation(-100)
          .url()}
        imageHover={
          imageUrlFor(buildImageObj(node.listingImage))
            .width(960)
            .height(960)
            .auto('format')
            .quality(80)
            .saturation(-100)
            .url()}
        author={node.author}
        title={node.title ? node.title[locale] : undefined}
      />
    </Link>
  )
}

const StyledLogo = styled.div`
  img {
    width: 50%;
    height: auto;
    max-width: 437px;
    margin-left: 50%;
    transform: translateX(-50%);
    outline: 1px dashed red;
  }
`;

const StyledBorderGridWrapper = styled.div`
  max-height: 900px;
  overflow: hidden;
  /* min-height: 50vh; */
`;

const StyledHiddenOnMobile = styled.div`
  display: block;

  ${breakpoint("md")`
    display: initial;
  `}
`;

const PlaceContainer = styled.div`
  position: absolute;
  bottom: 40px;
  width: 95%;
`;


const StyledSloganSVG = styled(SVG)`
  position: absolute;
  background-color: var(--color-orange);
  display: block;
  max-height: 400px;
  ${breakpoint("md")`
    width: 105%;
    left: -20px;
  `}
  ${breakpoint("lg")`
    max-height: 850px;
  `}
`;

const StyledLetterContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: var(--color-yellowish);
  /* .content-height-minus comes from master layout */
`;


const StyledLetterSvg = styled.div`
  background-color: var(--color-yellowish);
  margin: 20px;
  img {
    width: 100%;
  }

  ${breakpoint("sm")`
    padding: 2vw;
  `}

  ${breakpoint("xl")`
    margin: 20px;
    padding: 2.5vw;
  `}
`;

const LetterComponent = (props) => {
  const data = props;

  if (typeof data.image === 'object' && data.image != null) {
    var show = true;
  } else {
    var show = false;
  }
  if (data.reverse) {
    var reverseClass = "reverse-true";
  } else {
    reverseClass = "reverse-false"
  }
  return (
    <StyledLetterSvg>
      <img src={LetterSvg} className={reverseClass} alt="Huiput" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={CommunitySvg} className={reverseClass} alt="Community" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={LetterSvg} className={reverseClass} alt="Huiput" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={CommunitySvg} className={reverseClass} alt="Community" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={LetterSvg} className={reverseClass} alt="Huiput" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={CommunitySvg} className={reverseClass} alt="Community" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={LetterSvg} className={reverseClass} alt="Huiput" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
      <img src={CommunitySvg} className={reverseClass} alt="Community" />
      {(show) && (
        <img src={data.image.asset.url} className="speaker-image" alt={data.image.alt} />
      )}
    </StyledLetterSvg>
  );
};

const LetterAnimation = (props) => {
  let offset = props.letterHeight - props.ratioBoxHeight;
  let fromY = props.fromY ? String(props.fromY) : "0";
  if (props.reverse && props.fromY) offset = (props.letterHeight - props.ratioBoxHeight) - props.fromY;
  if (props.reverse && props.fromY) fromY = `-${props.fromY}`;
  const duration = props.duration ? props.duration : 250000;
  const reverse = props.reverse ? props.reverse : false;

  const n = useRef(0)
  const animationProps = useSpring({
    from: {
      transform: `translate3d(0, ${fromY}px, 0)`
    },
    to: {
      transform: `translate3d(0, -${offset}px, 0)`
    },
    config: {
      duration: duration,
    },
    reset: true,
    reverse: reverse,
  });
  return <animated.div style={animationProps}>{props.children}</animated.div>
}

const SloganAnimation = (props) => {
  const delay = 1000 * props.order;
  const n = useRef(0)
  const animationProps = useSpring({
    from: {
      opacity: 0,
    },
    delay: delay,
    to: {
      opacity: 1,
    },
    config: {
      duration: 0,
    },
  });
  return <animated.div style={animationProps}><StyledSloganSVG src={props.file} type="image/svg+xml" /></animated.div>
}

class LetterAnimationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerHeight: 0,
      letterHeight: 0
    }
  }

  onSize = size => {
    // console.log('Container has a height of', size.height);
    this.setState({
      containerHeight: size.height
    })
  }

  onLetterSize = size => {
    // console.log('Letter has a height of', size.height);
    this.setState({
      letterHeight: size.height
    })
  }

  render() {
    return (
      <SizeAware onSize={this.onSize}>
        <StyledLetterContainer className={this.props.className}>
          <LetterAnimation
            ratioBoxHeight={this.props.ratioBoxHeight}
            containerHeight={this.state.containerHeight}
            letterHeight={this.state.letterHeight}
            fromY={this.props.fromY}
            duration={this.props.duration}
            reverse={this.props.reverse}
          >
            <SizeAware onSize={this.onLetterSize}>
              <LetterComponent image={this.props.image} reverse={this.props.reverse} />
            </SizeAware>
          </LetterAnimation>
        </StyledLetterContainer>
      </SizeAware>
    )
  }
}

export default injectIntl(IndexPage);
